// LeadForm.js
import React, { useState } from "react";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";

const LeadForm = () => {
  const [step, setStep] = useState(1);
  const [lead, setLead] = useState({
    nombre: "",
    negocio: "",
    telefono: ""
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setLead({ ...lead, [e.target.name]: e.target.value });
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, "leads"), {
        ...lead,
        estado: "nuevo",
        fecha_creacion: new Date()
      });
      setSubmitted(true);
    } catch (error) {
      console.error("Error al guardar el lead:", error);
    } finally {
      setLoading(false);
    }
  };

  if (submitted) {
    return <div>Gracias por enviar tus datos. Nos pondremos en contacto.</div>;
  }

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Registro de Lead</h2>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="mb-4">
            <label className="block mb-1">Nombre:</label>
            <input
              type="text"
              name="nombre"
              value={lead.nombre}
              onChange={handleChange}
              required
              className="w-full border p-2 rounded"
            />
            <button type="button" onClick={nextStep} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded">
              Siguiente
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="mb-4">
            <label className="block mb-1">Nombre del negocio:</label>
            <input
              type="text"
              name="negocio"
              value={lead.negocio}
              onChange={handleChange}
              required
              className="w-full border p-2 rounded"
            />
            <div className="flex justify-between mt-2">
              <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-500 text-white rounded">
                Atrás
              </button>
              <button type="button" onClick={nextStep} className="px-4 py-2 bg-blue-500 text-white rounded">
                Siguiente
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="mb-4">
            <label className="block mb-1">Teléfono:</label>
            <input
              type="text"
              name="telefono"
              value={lead.telefono}
              onChange={handleChange}
              required
              className="w-full border p-2 rounded"
            />
            <div className="flex justify-between mt-2">
              <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-500 text-white rounded">
                Atrás
              </button>
              <button type="submit" disabled={loading} className="px-4 py-2 bg-green-500 text-white rounded">
                {loading ? "Enviando..." : "Enviar"}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default LeadForm;
