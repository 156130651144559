// WhatsappConfig.js
import React, { useState, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";

// Verifica el valor de la variable de entorno
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";
console.log("API URL:", API_URL);

const WhatsappConfig = () => {
  const [connectionStatus, setConnectionStatus] = useState("");
  const [qr, setQR] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  // Función para iniciar la conexión con WhatsApp
  const handleConnect = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/whatsapp/connect`);
      const data = await response.json();
      setConnectionStatus(data.message);
    } catch (error) {
      console.error("Error al conectar:", error);
      setConnectionStatus("Error al conectar con WhatsApp.");
    } finally {
      setLoading(false);
    }
  };

  // Función para consultar el estado (QR y conexión)
  const fetchStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/api/whatsapp/status`);
      const data = await response.json();
      setConnectionStatus(data.status);
      setQR(data.qr);
    } catch (error) {
      console.error("Error al obtener el estado:", error);
    }
  };

  // Consulta periódica cada 3 segundos para actualizar el estado y el QR
  useEffect(() => {
    const intervalId = setInterval(fetchStatus, 3000);
    return () => clearInterval(intervalId);
  }, []);

  // Funciones para testear el envío de mensajes
  const handleTestText = async () => {
    try {
      const response = await fetch(`${API_URL}/api/whatsapp/send/text?phone=${phone}`);
      const data = await response.json();
      console.log("Test texto:", data);
    } catch (error) {
      console.error("Error enviando mensaje de texto:", error);
    }
  };

  const handleTestImage = async () => {
    try {
      const response = await fetch(`${API_URL}/api/whatsapp/send/image?phone=${phone}`);
      const data = await response.json();
      console.log("Test imagen:", data);
    } catch (error) {
      console.error("Error enviando mensaje de imagen:", error);
    }
  };

  const handleTestAudio = async () => {
    try {
      const response = await fetch(`${API_URL}/api/whatsapp/send/audio?phone=${phone}`);
      const data = await response.json();
      console.log("Test audio:", data);
    } catch (error) {
      console.error("Error enviando mensaje de audio:", error);
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-4">Configuración de Whatsapp</h2>
      <p>Estado de conexión: {connectionStatus}</p>

      {qr && (
        <div className="mt-4">
          <p>Escanea el siguiente QR:</p>
          <QRCodeCanvas value={qr} size={256} />
        </div>
      )}

      <button
        onClick={handleConnect}
        className="mt-4 px-4 py-2 bg-primary text-white rounded"
        disabled={loading}
      >
        {loading ? "Conectando..." : "Conectar a Whatsapp"}
      </button>

      <div className="mt-8">
        <h3 className="text-xl font-semibold">Enviar Mensajes de Prueba</h3>
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Ingrese número de teléfono"
          className="mt-4 p-2 border rounded w-full"
        />
        <div className="mt-4 flex flex-col space-y-2">
          <button onClick={handleTestText} className="px-4 py-2 bg-blue-500 text-white rounded">
            Testear mensaje de texto
          </button>
          <button onClick={handleTestImage} className="px-4 py-2 bg-green-500 text-white rounded">
            Testear mensaje de imagen
          </button>
          <button onClick={handleTestAudio} className="px-4 py-2 bg-purple-500 text-white rounded">
            Testear mensaje de audio
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatsappConfig;
