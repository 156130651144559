import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    
   apiKey: "AIzaSyB92q3TwGVOxTHmLUjK5eAjZgTfRQXRZoM",
   authDomain: "app-invita.firebaseapp.com",
   projectId: "app-invita",
   storageBucket: "app-invita.firebasestorage.app",
   messagingSenderId: "869772583661",
   appId: "1:869772583661:web:0e17a5c6a61bb1907662e4",
   measurementId: "G-6VE5FL35ZZ"
  
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Solo una instancia de Firestore
export { auth, db, firebaseConfig }; // No es necesario exportar `firestore` con otro nombre

   
   
   

