// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import MainLayout from "./components/MainLayout";
import AdminHome from "./pages/AdminHome";
import WhatsappConfig from "./components/WhatsappConfig";
import LeadForm from "./components/LeadForm"; // Asegúrate de que la ruta sea la correcta

const ProtectedRoute = ({ children, role }) => {
  // Suponiendo que useAuth gestiona la autenticación interna
  // Si el formulario es público, no se usa ProtectedRoute aquí
  return children;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registro" element={<RegisterPage />} />
        <Route path="/lead" element={<LeadForm />} />

        {/* Rutas protegidas */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute role="admin">
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<AdminHome />} />
          <Route path="whatsapp" element={<WhatsappConfig />} />
        </Route>

        <Route path="/cobrador" element={<div>Área de Cobrador</div>} />

        {/* Redirección desde la raíz */}
        <Route path="/" element={<Navigate to="/lead" />} />
        <Route path="*" element={<Navigate to="/lead" />} />
      </Routes>
    </Router>
  );
};

export default App;
